// Page for individual News Items;
import React from 'react';
import SEO from '../components/seo';
import contentParser from 'gatsby-wpgraphql-inline-images';
import FluidImage from '../components/fluid-image.js';

const PageTemplate = ({ pageContext }) => {
  console.log({pageContext})
  const {
    content,
    title,
    pluginOptions,
    pageAesthetic: {
      titlebanner
    }
  } = pageContext;

  const cleanedContent = content.replace('<p>~~break~~</p>', '');

  return (
    <>
      <SEO title={title}/>
      <section>
        <header className='flex justify-center text-center w-full my-8'>
          <FluidImage className='w-1/3' image={titlebanner}/>
        </header>
        <article className='mb-16'>
            {contentParser({ content: cleanedContent }, pluginOptions)}
        </article>
      </section>
    </>
  );
};

export default PageTemplate;
